<!-- <template>
	<el-dialog title="选择地址" :visible.sync="showMap" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="cancel" width="800px" :fullscreen="true">
		<baidu-map :center="center" :scroll-wheel-zoom="true" @ready="initMap">
			<bm-view class="map"></bm-view>
			<bm-control>
				<el-input v-model="keyword" placeholder="请输入地址" style="margin-left: 10px;margin-top: 10px;" clearable>
				</el-input>
			</bm-control>
			<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
			<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
			<bm-local-search :keyword="keyword" :resultRender="true" :auto-viewport="true" :pageCapacity="5" :panel="false" style="margin-top: 10px;" @infohtmlset="handleInfoHtmlSet"
				@searchcomplete="handleSearchComplete">
			</bm-local-search>
		</baidu-map>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" size="small" plain icon="el-icon-check" @click="confirm">确 定 </el-button>
			<el-button size="small" plain icon="el-icon-close" @click="cancel">取 消 </el-button>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		name: 'b-map',
		data() {
			return {
				showMap: true,
				BMap: null,
				map: null,
				keyword: '',
				center: '苏州',
				address: null
			}
		},
		methods: {
			initMap({
				BMap,
				map
			}) {
				this.BMap = BMap
				this.map = map
			},
			handleInfoHtmlSet(address) {
				const gc = new this.BMap.Geocoder()
				console.log(address)
				gc.getLocation(address.point, res => {
					this.address = res
				})
			},
			handleSearchComplete() {
				this.address = null
			},
			confirm() {
				if (!this.address) {
					this.$message.warning('请选择地址')
					return
				}
				console.log(this.address);
				this.$emit('mapChooseComplete', this.address)
			},
			cancel() {
				this.$emit('mapClose')
			}
		}
	}
</script>
<style lang="scss" scoped>
	.map {
		width: 100%;
		height: 500px;
	}
</style> -->
<template>
	<el-dialog :visible.sync="showMap" slot="—" :title="title" :width="width" top="5vh" custom-class="baidu-map" @close="cancel" :close-on-click-modal="false">
		<baidu-map :style="mapStyle" :map-click="false" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="onBaiduMapReady" @click="getClickInfo" @moveend="syncMoveend"
			@zoomend="syncCenterAndZoom">
			<bm-view style="width: 100%; height: 100%;" />
			<bm-marker :position="{lng: center.lng, lat: center.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE" />
			<bm-control :offset="{width: '10px', height: '10px'}">
				<bm-auto-complete v-model="keyword" :sug-style="{zIndex: 999999}">
					<el-input v-model="keyword" type="text" placeholder="请输入地名关键字" clearable>
						<i slot="prefix" class="el-input__icon el-icon-search" />
					</el-input>
				</bm-auto-complete>
			</bm-control>
			<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" show-address-bar auto-location />
			<bm-local-search :keyword="keyword" :auto-viewport="true" :panel="false" />
		</baidu-map>
		<span slot="footer">
			<el-button icon="el-icon-close" @click="cancel">取 消</el-button>
			<el-button icon="el-icon-place" type="primary" @click="confirm">确 定</el-button>
		</span>
	</el-dialog>
</template>
<script>
	import {
		BaiduMap,
		BmControl,
		BmView,
		BmAutoComplete,
		BmLocalSearch,
		BmMarker,
		BmGeolocation
	} from 'vue-baidu-map'
	export default {
		name: 'b-map',
		components: {
			BaiduMap,
			BmControl,
			BmView,
			BmAutoComplete,
			BmLocalSearch,
			BmMarker,
			BmGeolocation
		},
		props: {
			// 在子组件中使用 props 选项去接收来自父组件传递过来的数据
			// dialogVisible: Boolean,
			mapHeight: {
				type: Number,
				default: 450
			},
			title: {
				type: String,
				default: '选择目标位置'
			},
			width: {
				type: [Number, String],
				default: '85%'
			},
			height: {
				type: [Number, String],
				default: '80%'
			}
		},
		data: function () {
			return {
				showMap: true,
				BMap: null, // 地图组件是否就绪
				// showMapComponent: this.dialogVisible,
				keyword: '', // 地区搜索的信息
				mapStyle: {
					width: '100%',
					height: this.mapHeight + 'px'
				},
				center: {
					lng: 120.59241222959322,
					lat: 31.303564074441768
				},
				choosedLocation: {
					province: '',
					city: '',
					district: '',
					addr: ''
				},
				zoom: 15
			}
		},
		// watch: {
			// dialogVisible: function (currentValue) {
			// 	this.showMapComponent = currentValue
			// 	if (currentValue) {
			// 		this.keyword = ''
			// 	}
			// }
		// },
		methods: {
			// ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
			onBaiduMapReady(e) {
				console.log('返回BMap类和map实例', e)
				const that = this
				this.BMap = e.BMap
				if (this.BMap) {
					// 获取定位地址信息并赋值给声明变量
					// Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch() 方法详解 ：https://blog.csdn.net/zyz00000000/article/details/82774543
					const geolocation = new this.BMap.Geolocation()
					// 通过 getCurrentPosition() 方法：获取当前的位置信息
					geolocation.getCurrentPosition(res => {
						console.log('返回详细地址和经纬度', res)
						const {
							lng,
							lat
						} = res.point
						const {
							province,
							city,
							district,
							street,
							street_number
						} = res.address
						that.center = {
							lng,
							lat
						}
						that.choosedLocation = {
							province,
							city,
							district,
							addr: street + street_number,
							lng,
							lat
						}
					})
				}
			},
			/** *
			 * 地图点击事件。
			 */
			getClickInfo(e) {
				// 调整地图中心位置
				this.center.lng = e.point.lng
				this.center.lat = e.point.lat
				// 此时已经可以获取到BMap类
				if (this.BMap) {
					const that = this
					// Geocoder() 类进行地址解析
					// 创建地址解析器的实例
					const geoCoder = new this.BMap.Geocoder()
					// getLocation() 类--利用坐标获取地址的详细信息
					// getPoint() 类--获取位置对应的坐标
					geoCoder.getLocation(e.point, function (res) {
						console.log('获取经纬度', e.point, '获取详细地址', res)
						const addrComponent = res.addressComponents
						const surroundingPois = res.surroundingPois
						const province = addrComponent.province
						const city = addrComponent.city
						const district = addrComponent.district
						let addr = addrComponent.street
						if (surroundingPois.length > 0 && surroundingPois[0].title) {
							if (addr) {
								addr += `-${surroundingPois[0].title}`
							} else {
								addr += `${surroundingPois[0].title}`
							}
						} else {
							addr += addrComponent.streetNumber
						}
						that.choosedLocation = {
							province,
							city,
							district,
							addr,
							...that.center
						}
					})
				}
			},
			syncCenterAndZoom(e) {
				// 返回地图当前的缩放级别
				this.zoom = e.target.getZoom()
			},
			syncMoveend(e) {
				this.center.lng = e.target.re.lng;
				this.center.lat = e.target.re.lat;
			},
			/** *
			 * 确认
			 */
			confirm: function () {
				console.log(this.choosedLocation)
				this.$emit('mapChooseComplete', this.choosedLocation)
			},
			/** *
			 * 取消
			 */
			cancel: function () {
				this.$emit('mapClose')
			}
		}
	}
</script>
<style lang="scss">
	.baidu-map {
		.el-dialog__body {
			padding: 5px !important;
		}
	}
</style>
